import { Parallax } from "react-scroll-parallax";
import { Button } from "../../components/Button/Button";
import './Landing.css';
import { useMediaQuery } from 'react-responsive'
import { largeScreen, scrollTo } from "../Utils";

export const Landing = () => {

    const isLargeScreen = useMediaQuery(largeScreen)
    return (
        <section className="landing">
            {
                isLargeScreen ? 
                <Parallax speed={-30} className="background mirror">
                    <img
                        className="mirror"
                        src="morgan-dual-mirror.jpg"
                        alt="Un magicien laisse apparaître une ombre de lui dans un miroir" />
                </Parallax>
                :
                <Parallax translateY={[-30, 30]} className="background mask">
                    <img
                        src="background/50-nuances-de-morgan.jpg"
                        alt="Mentaliste avec les yeux bandés effectue un tour de prestidigitateur sur scène" />
                </Parallax>
            }
            <h1>Morgan Damès</h1>
            <div className="subtitle-wrapper">
                <div className="subtitle">MAGICIEN</div>
                <img src="spade.png" alt="As de pique" />
                <div className="subtitle">MENTALISTE</div>
                <img src="spade.png" alt="As de pique" />
                <div className="subtitle">HYPNOTISEUR</div>
            </div>
            <div className="actions">
                <Button target="contact" onClick={scrollTo}>
                    Entrer en contact
                </Button>
                <Button target="about" variant="outline" color="contrast" onClick={scrollTo}>
                    En savoir plus
                </Button>
            </div>
        </section>
    );
}