import './Signature.css';

export const Signature = () => (
    <div className="signature">
        <small>
            Création&nbsp;
            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/r%C3%A9mi-drissi-91281714b/"
            >
                Rémi Drissi
            </a>
        </small>
    </div>
)