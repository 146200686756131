import './LoadingPage.css';

function LoadingPage({isLoaded, showOverlay}) {
  return (
    <div className={`loading-page ${isLoaded ? 'is-loaded' : ''} ${showOverlay ? 'show-overlay' : ''}`}>
      <div className="card-flip">
        <div className="recto">
          <div className="pip pip-top">
            M
            <img src="spade-black-x32.png" alt="spade"/>
          </div>
          <img src="spade-black-x64.png" alt="spade" className="big-spade"/>
          <div className="pip pip-bottom">
            M
            <img src="spade-black-x32.png" alt="spade"/>
          </div>
        </div>
        <div className="verso">
          <svg width="434" height="238" viewBox="0 0 434 238" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo-md">
            <path d="M96 0.5H0V29L25.5 34V204.5L0 209.5V238H98.5V209.5L70.5 204.5L67.5 50L141.5 235H177.5L232.5 93V47L209 41.5L161.5 167.5L96 0.5Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M222 0.5L330.5 0C330.5 0 434 0 434 119.5C434 239 330.5 238 330.5 238H222V209.5L247.5 204V34.5L222 29V0.5ZM325.328 37H295.5V201.5H325.328C325.328 201.5 386.5 204 386.5 119.5C386.5 35 325.328 37 325.328 37Z" fill="white"/>
          </svg>
        </div>
      </div>
      <p>Préparation des cartes...</p>
    </div>
  )
}

export default LoadingPage;
