import { ParallaxProvider } from 'react-scroll-parallax';
import { Landing } from './Sections/Landing/Landing';
import { About } from './Sections/About/About';
import { Performances } from './Sections/Performances/Performances';
import { Demo } from './Sections/Demo/Demo';
import { Testimonials } from './Sections/Testimonials/Testimonials';
import { Contact } from './Sections/Contact/Contact';
import OnImagesLoaded from 'react-on-images-loaded';
import LoadingPage from './LoadingPage';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

function Home() {
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const [isOverlayDisplayed, setIsOverlayDisplayed] = useState(true)
  const setLoaded = () => {
    setIsPageLoaded(true);
    setTimeout(() => {
      setIsOverlayDisplayed(false)
    }, 1100)
  }
  useEffect(() => {

  }, [])

  useEffect(() => {
    const trackingId = process.env.REACT_APP_ANALYTICS_TRACKING_ID
    ReactGA.initialize(trackingId);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Page principale" });
  }, [])

  return (
    <OnImagesLoaded delay={1000} onLoaded={ () => {setLoaded()} }>
      <LoadingPage isLoaded={isPageLoaded} showOverlay={isOverlayDisplayed} />
      <ParallaxProvider>
        <div className="App">
          <Landing />
          <About />
          <Performances />
          <Demo />
          <Testimonials />
          <Contact />
        </div>
      </ParallaxProvider>
    </OnImagesLoaded>
  );
}

export default Home;
