import { Button } from "../../components/Button/Button";
import { scrollTo } from "../Utils";

export const FlatPerformance = ({
    name,
    image,
    children,
}) => {
    return (
        <div className="performance">
            <img src={ image } draggable={false} alt={ name } />
            <div className="title-wrapper">
                <span className="dot"></span>
                <span className="stroke"></span>
                <h3 className="title">{ name }</h3>
                <span className="stroke"></span>
                <span className="dot"></span>
            </div>
            <p dangerouslySetInnerHTML={{ __html: children }}></p>
            <Button variant="outline" onClick={scrollTo} target="contact">
                Demander cette prestation
            </Button>
        </div>
    );
}