import { Parallax } from "react-scroll-parallax";
import { Button } from "../../components/Button/Button";
import './About.css';
import { useMediaQuery } from "react-responsive";
import { scrollTo, mobileQuery } from "../Utils";

export const About = () => {
    const isSmallScreen = useMediaQuery(mobileQuery)
    return (
        <section className="about" id="about">
            {
                isSmallScreen ?
                <Parallax translateY={[-30, 30]} className="background">
                    <img src="background/mobile/cartes-mains.jpg" alt="Magicien passant ses cartes d'une main à l'autre" />
                </Parallax>
                :
                <Parallax speed={-30} className="background">
                    <img src="cards.jpg" alt="Magicien passant ses cartes d'une main à l'autre" />
                </Parallax>
            }
            <h2>Ajoutez une touche <strong>magique</strong> à vos événements</h2>
            <p>
                Résidant à Cannes, j’anime vos soirées, mariages, anniversaires et autres événements privés dans <strong>toute la région PACA</strong>, depuis plus de 5 ans.
                <br/><br/>
                De la science à l’humour, du mentalisme à la magie traditionnelle, je propose aujourd’hui des <strong>prestations variées</strong> en énergie et en couleurs.
                <br/><br/>
                Et puisque chaque événement est différent, je sais toujours m’adapter pour créer un <strong>show unique</strong>.
            </p>
            <div className="actions">
                <Button variant="outline" target="performances" onClick={scrollTo}>
                    Voir toutes les prestations
                </Button>
            </div>
        </section>
    );
}