import { Card } from './Card';
import { LogoCarousel } from './LogoCarousel';
import './Testimonials.css';

export const Testimonials = () => {

    const logos = [
        {
            name: 'Hotel Campanile',
            link: 'campanile.png',
            group: 1,
        },
        {
            name: 'FL Bar',
            link: 'fl-bar.png',
            group: 2,
        },
        {
            name: 'Villa Salato',
            link: 'villa-salato.png',
            group: 1,
        },
        {
            name: 'Partouche',
            link: 'partouche.png',
            group: 2,
        },
        {
            name: 'Chez Vito Brasserie',
            link: 'vito.png',
            group: 1,
        },
        {
            name: 'Orange',
            link: 'orange.png',
            group: 2,
        },
        {
            name: 'Casino de Briançon',
            link: 'casino-briancon.png',
            group: 1,
        },
        {
            name: 'Le Saint Laurent',
            link: 'saint-laurent.png',
            group: 2,
        },
        {
            name: 'Les 3 Brasseurs',
            link: '3-brasseurs.png',
            group: 1,
        },
        {
            name: 'Avva Garden',
            link: 'avva-garden.png',
            group: 2,
        },
        {
            name: 'Gold Eagles',
            link: 'gold-eagles.png',
            group: 1,
        },
        {
            name: 'Domaine Verchant',
            link: 'verchant.png',
            group: 2,
        },
        {
            name: 'Bastide du bois Bréant',
            link: 'bastide-bois-breant.png',
            group: 1,
        },
        {
            name: 'Intervascular (Getinge)',
            link: 'getinge.png',
            group: 2,
        },
        {
            name: 'Cineum de Cannes',
            link: 'cineum.png',
            group: 1,
        },
        {
            name: 'Rosana Cannes',
            link: 'rosana.png',
            group: 2,
        },
        {
            name: 'Z5 Aix-en-Provence',
            link: 'z5.png',
            group: 1,
        },
    ]

    return (
        <section className="testimonials">
            <h2>Ils m'ont fait confiance</h2>
            <div className="cards">
                <Card author="Sébastien" role="Responsable" company="Les 3 Brasseurs">
                    Morgan est un artiste au top, super sympa, à l’écoute. Après une collaboration de presque un an avec les 3 brasseurs du Pontet, je recommande vraiment ce magicien très professionnel.
                </Card>
                <Card author="Adeline" role="Directrice" company="MBS - La bonne adresse">
                    Nous avons fait appel à Morgan pour animer notre repas d’entreprise de fin d’année. Il a été super ! Il s’est adapté à nous avec plaisir et nous a régalé de ses tours de cartes et de magie. Petits et grands ont été émerveillés. Nous remercions Morgan pour cette soirée magique !
                </Card>
                <Card author="Jérôme" role="Directeur" company="Avva Garden">
                    Avec plus d'un tour dans son sac, Morgan a su charmer notre clientèle.Interactif et très professionnel, avec beaucoup d'humour Morgan a su à plusieurs reprises et avec des clientèles différentes capter l'attention de tous avec des numéros de magie et de mentalisme époustouflants.
                </Card>
            </div>
            <div className="logos">
                <LogoCarousel logos={logos} />
            </div>
            <div>
                <div className="logos mobile">
                    <LogoCarousel logos={logos} group={1} />
                </div>
                <div className="logos mobile">
                    <LogoCarousel logos={logos} group={2} reverse />
                </div>
            </div> 

        </section>
    );
}