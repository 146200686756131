/* Media query mobile */
export const mobileQuery = {
  query: '(max-width: 600px)'
}

/* Media query mobile+tablette */
export const smallScreenQuery = {
    query: '(max-width: 900px)'
}

/* Media query large+extralarge */
export const largeScreen = {
  query: '(min-width: 1200px)'
}

/* Scrolle jusqu'à une destination cible */
export const scrollTo = (e) => {
    const target = e.target.getAttribute("target");
    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
}

export const sendEmail = (formData) => {
  const url = process.env.REACT_APP_HOST
  console.log(url);
  return fetch(url, {
    // mode: 'cors',
    method: 'POST',
    body: formData
  })
}