export const LogoCarousel = ({logos, group = null, reverse = false}) => {

    const renderLogos = (logoList, group) => {
        return logoList.map((logo, i) => {
            if (group && group !== logo.group) return null;
            return (
                <img 
                    key={i}
                    draggable={false}
                    src={`logos/${logo.link}`}
                    alt={logo.name}/>
            )            
        })
    }

    return (
        <div className={`logo-carousel ${reverse ? 'reverse' : ''}`}>
            <div className="list">
                { renderLogos(logos, group) }
            </div>
            <div className="list copy">
                { renderLogos(logos, group) }
            </div>
        </div>
    );
}