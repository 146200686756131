import { Button } from '../../components/Button/Button';
import { SocialLinks } from '../Contact/SocialLinks/SocialLinks';
import './MessageSent.css';

export const MessageSent = () => {
    return (
        <section className="message-sent">
            <div className="card">
                <span className="fa envelope">envelope-circle-check</span>
                <h2>Votre message a été envoyé !</h2>
                <p>
                Ne vous en faites pas, je vous répondrai dans les plus brefs délais.
                <br/>
                Merci pour votre confiance, je vous réserve bien des surprises illusionnistiques !
                </p>
                <hr/>
                <Button href="/">
                    <span className="fas">arrow-left</span>
                    Retour à l'accueil
                </Button>
            </div>
            <div className="wrapper">
                <h3>Suivez-moi sur les réseaux</h3>
                <SocialLinks/>
            </div> 
        </section>
    );
}