import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MessageSent } from './Sections/MessageSent/MessageSent';
import Home from './Home';
import './App.css';
import LoadingPage from './LoadingPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="card" element={<LoadingPage isLoaded={false} showOverlay={true}/>} />
        <Route path="message-envoye" element={<MessageSent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
