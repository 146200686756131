import './Button.css';

export const Button = ({variant='plain', color='primary', children, ...rest}) => {
    const classes = `button ${variant} ${color}`
    if (rest.href) {
        return <a className={classes} {...rest}>
            { children }
        </a>
    } else {
        return <button className={classes} {...rest}>
            { children }
        </button>
    }
}