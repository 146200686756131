import './Perf.css';
import './Performances.css';
import { CarouselPerformance } from "./Carousel/CarouselPerformance";
import { FlatPerformance } from "./FlatPerformance";
import { useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';
import { scrollTo, smallScreenQuery } from '../Utils';

export const Performances = () => {

    const performances = [
        {
            name: 'Magie',
            background: 'background/magie-nb.jpg',
            image: 'magie.jpg',
            description: 'Vous ne rêvez pas, c’est votre cerveau qui vous joue des tours.<br/>Durant ce show, retrouvez les classiques de la magie, de l’illusion et plus encore.'
        },
        {
            name: 'Mentalisme',
            background: 'background/mentalisme-nb.jpg',
            image: 'mentalisme.jpg',
            description: 'Nous disposons de cinq sens...<br/>À la manière de Sherlock Holmes, j’en utiliserai un sixième pour lire dans vos pensées.'
        },
        {
            name: 'Hypnose',
            background: 'background/hypnose-nb.jpg',
            image: 'hypnose.jpg',
            description: '...À la fin du décompte, vous dormirez.”<br/>Laissez-moi questionner votre subconscient et explorons ensemble les limites de votre imaginaire.'
        },
        {
            name: 'Spectacle pour enfants',
            background: 'background/enfants-nb.jpg',
            image: 'enfants.jpg',
            description: 'Adultes, vous redeviendrez enfant, et enfants, vous vous émerveillerez <br/>pour continuer de rêver avec mes tours de magie, aussi drôles qu’époustouflants.'
        },
    ]

    const isSmallScreen = useMediaQuery(smallScreenQuery)

    const framesExtension = 3

    const initialInd = 1
    const [currentInd, setCurrentInd] = useState(initialInd)
    
    const handlePrev = () => {
        const previousInd = currentInd - 1
        setCurrentInd(previousInd)
    }
    const handleNext = () => {
        const nextInd = currentInd + 1
        setCurrentInd(nextInd)
    }

    const getPerfInd = (i) => {
        let perfInd = i % performances.length
        if (perfInd < 0) perfInd += performances.length
        return perfInd
    }

    const performancesCarousel = () => {

        const toBeDisplayed = []

        for (let i = currentInd - framesExtension; i < currentInd + framesExtension; i++) {
            const perfInd = getPerfInd(i)
            const { name, image } = performances[perfInd]
            const div = (
                <div style={{transform: `translateX(${-640 * (currentInd - i)}px)`}} key={`performance-${i}`}>
                    <CarouselPerformance
                        selected={currentInd === i}
                        name={name}
                        image={image}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        isPrev={currentInd-i > 0}
                        isNext={currentInd-i < 0}
                    />
                </div>
            )
            toBeDisplayed.push(div)
        } 

        return toBeDisplayed
    }

    const displayDescription = () => {
        const perfInd = getPerfInd(currentInd)
        return performances.map((performance, i) => (
            <p key={`desc-${i}`} className={i === perfInd ? 'selected' : ''} dangerouslySetInnerHTML={{__html: performance.description}}></p>
        ))
    }

    const displayBackground = () => {
        const perfInd = getPerfInd(currentInd)
        return (
            <Parallax speed={-50} className="background">
                {
                    performances.map((performance, i) => (
                        <img
                            key={`bg-${i}`}
                            src={performance.background}
                            alt={performance.name}
                            className={i === perfInd ? 'selected' : ''} />
                    ))
                }
            </Parallax>
        )
    }

    return (
        <section className="performances" id="performances">
            { displayBackground() }
            <h2>Des <strong>prestations</strong> pour tous les goûts</h2>
            <p>Vers laquelle vous laisserez-vous porter ?</p>
            {
                isSmallScreen ?
                performances.map((performance) => (
                    <FlatPerformance
                        key={ performance.name }
                        name={ performance.name }
                        image={ performance.image }
                    >
                        { performance.description }
                    </FlatPerformance>
                ))
                :
                <>
                    <div className="wrapper">
                        { performancesCarousel() }
                    </div>
                    <div className="description">
                        { displayDescription() }
                    </div>
                    <Button
                        variant="outline"
                        target="contact"
                        onClick={scrollTo}>
                        Demander cette prestation
                    </Button>
                </>
            }
        </section>
    );
}