import './PerformanceCarousel.css';

export const CarouselPerformance = ({
    name,
    image,
    selected,
    isPrev,
    handlePrev,
    isNext,
    handleNext,
    ...rest
}) => {
    const classes = ['performance']
    if (selected) {classes.push('selected')}
    if (isPrev) {classes.push('prev')}
    if (isNext) {classes.push('next')}
    const handleClick = () => {
        if (isPrev) handlePrev();
        if (isNext) handleNext();
    }
    return (
        <div className={ classes.join(' ') } { ...rest } onClick={ handleClick }>
            <img src={ image } draggable={false} alt={ name }  />
            <h3 className="title">{ name }</h3>
        </div>
    );
}