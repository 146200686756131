export const Card = ({children, author, role, company}) => {
    return (
        <div className="card">
            <span className="quote">quote-left</span>
            <p>{children}</p>
            <div>
                <div className="author">
                    {author}
                </div>
                <div className="from">
                    {role} @ {company}
                </div>
            </div>
        </div>
    );
}