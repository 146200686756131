// import { useMediaQuery } from 'react-responsive';
import { Button } from '../../components/Button/Button';
import './Contact.css';
import { sendEmail } from '../Utils';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { SocialLinks } from './SocialLinks/SocialLinks';
import { Signature } from '../Signature/Signature';

export const Contact = () => {
    const navigate = useNavigate();

    const [emailError, setEmailError] = useState(null)
    const [messageError, setMessageError] = useState(null)
    const [formError, setFormError] = useState(null)

    const checkEmail = (email) => {
        if (email === null || email.replace(/ /g, '') === '') {
            setEmailError('Saisissez une adresse pour que je puisse vous recontacter')
            return false
        } else {
            const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
            if (isValid) {
                setEmailError(null)
                return true
            } else {
                setEmailError('L\'adresse saisie semble ne pas être correcte.')
                return false
            }
        }
    }

    const handleEmailChanged = (e) => {
        const email = e.target.value
        checkEmail(email)
    }

    const checkMessage = (message) => {
        if (message === null || message.replace(/ /g, '') === '') {
            setMessageError("Je pourrais deviner votre prénom... Mais pas votre message !")
            return false
        } else {
            setMessageError(null)
            return true
        }
    }
    const handleMessageChanged = (e) => {
        const message = e.target.value
        checkMessage(message)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);

        const isEmailValid = checkEmail(formData.get('email'))
        const isMessageValid = checkMessage(formData.get('message'))
        
        if (isEmailValid && isMessageValid) {
            try {
                sendEmail(formData)
                .then(response => {
                    if (response.status === 200) {
                        navigate('/message-envoye')
                    } else {
                        setFormError("L'envoi de l'e-mail a échoué")
                    }
                })
                .catch(() => {
                    setFormError("L'envoi de l'e-mail a échoué")
                })
            } catch (e) {
                setFormError("L'envoi de l'e-mail a échoué")
            }
        }
        return false
    }

    return (
        <section className="contact" id="contact">
            <h2>Vous souhaitez animer un évènement ?</h2>
            <div className="wrapper">
                <div className="card form-card">
                    <h3>Entrons en contact</h3>
                    <p>Laissez-moi vos coordonnées, et je reviendrai vers vous... En un claquement de doigts !</p>
                    <form onSubmit={handleSubmit}>
                        <div className="input-group">
                            <label htmlFor="email">VOTRE ADRESSE E-MAIL</label>
                            <input
                                id="email"
                                name="email"
                                placeholder="Je ne la ferai pas disparaître..."
                                spellCheck={ false }
                                className={ emailError ? 'error' : null }
                                onBlur={ handleEmailChanged } />
                            {
                                emailError ? 
                                <small className="error-message">{ emailError }</small>
                                : null
                            }
                        </div>
                        <div className="input-group">
                            <label htmlFor="message">MESSAGE</label>
                            <textarea
                                id="message"
                                name="message"
                                placeholder="Écrivez-moi un message..."
                                className={ messageError ? 'error' : null}
                                rows="4"
                                spellCheck={ false }
                                onBlur={ handleMessageChanged }
                            ></textarea>
                            {
                                messageError ? 
                                <small className="error-message">{ messageError }</small>
                                : null
                            }
                        </div>
                        <Button type="submit">Envoyer</Button>
                        {
                            formError ? 
                            <small className="error-message">{ formError }</small>
                            : null
                        }
                    </form>
                </div>
                <div class="contact-details">
                    {/*{
                        isLargeScreen ?
                        <div className="card outline">
                            <h3>Formats de prestation</h3>
                            <ul className="formats">
                                <li>Close-up 2 heures</li>
                                <li>Spectacle 1 heure</li>
                                <li>Sur mesure</li>
                            </ul>
                        </div>
                        : null
                    }*/}
                    <div className="card outline">
                        <h3 style={{whiteSpace: "nowrap"}}>Mes coordonnées</h3>
                        <div>
                            <a className="contact-link" href="tel:+33630417828">
                                <i class="icon">phone</i>
                                06 30 41 78 28
                            </a>
                            <a className="contact-link" href="mailto:morgan.dames@gmail.com">
                                <i class="icon">@</i>
                                morgan.dames@gmail.com
                            </a>
                        </div>
                    </div>
                    <div className="card none">
                        <h3>Me suivre</h3>
                        <SocialLinks/>
                    </div>
                    <Signature/>
                </div>
            </div>
        </section>
    );
}