import './SocialLinks.css';

export const SocialLinks = () => {
    return (
        <ul className="social-links">
            <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/morgan.dames/">instagram</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/Morgan.DAMES">facebook</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/@MorganDames/featured">youtube</a></li>
        </ul>
    )
}