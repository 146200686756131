import './Demo.css';

export const Demo = () => {
    return (
        <section className="demo">
            <h2>Une démonstration ?</h2>
            <div className="video">
                <iframe 
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/uLg7xxUS3fY"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
                </iframe>
            </div>
        </section>
    );
}